import i18n from '@/i18n/index';

function parseServiceStatus(status) {
  let text;
  switch (status) {
    case 'ASAP':
      text = i18n.t('serviceStatus.available');
      break;

    case 'SCHEDULED':
      text = i18n.t('serviceStatus.scheduled');
      break;

    case 'UNAVAILABLE':
      text = `${i18n.t('serviceStatus.unavailable')}`;
      break;

    default:
      text = '';
      break;
  }
  return text;
}

export default parseServiceStatus;
