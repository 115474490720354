export const cityList = [
  { label: 'cityList.allCounty', value: 'none' },
  { label: 'cityList.TaipeiCity', value: '台北市' },
  { label: 'cityList.NewTaipeiCity', value: '新北市' },
  { label: 'cityList.TaoyuanCity', value: '桃園市' },
  { label: 'cityList.TaichungCity', value: '台中市' },
  { label: 'cityList.TainanCity', value: '台南市' },
  { label: 'cityList.KaohsiungCity', value: '高雄市' },
  { label: 'cityList.KeelungCity', value: '基隆市' },
  { label: 'cityList.YilanCounty', value: '宜蘭縣' },
  { label: 'cityList.HsinchuCity', value: '新竹市' },
  { label: 'cityList.HsinchuCounty', value: '新竹縣' },
  { label: 'cityList.MiaoliCounty', value: '苗栗縣' },
  { label: 'cityList.ChanghuaCounty', value: '彰化縣' },
  { label: 'cityList.NantouCounty', value: '南投縣' },
  { label: 'cityList.ChiayiCity', value: '嘉義市' },
  { label: 'cityList.ChiayiCounty', value: '嘉義縣' },
  { label: 'cityList.YunlinCounty', value: '雲林縣' },
  { label: 'cityList.PenghuCounty', value: '澎湖縣' },
  { label: 'cityList.KinmenCounty', value: '金門縣' },
  { label: 'cityList.PingtungCounty', value: '屏東縣' },
  { label: 'cityList.TaitungCounty', value: '台東縣' },
  { label: 'cityList.HualienCounty', value: '花蓮縣' },
  { label: 'cityList.LienchiangCounty', value: '連江縣' },
];

export const areaList = {
  none: [{ label: 'areaList.all', value: '' }],
  台北市: [
    { label: 'areaList.all', value: '台北市' },
    { label: 'areaList.ZhongZhengDistrict', value: '台北市中正區' },
    { label: 'areaList.DaTongDistrict', value: '台北市大同區' },
    { label: 'areaList.ZhongShanDistrict', value: '台北市中山區' },
    { label: 'areaList.SongShanDistrict', value: '台北市松山區' },
    { label: 'areaList.DaAnDistrict', value: '台北市大安區' },
    { label: 'areaList.WanHuaDistrict', value: '台北市萬華區' },
    { label: 'areaList.XinYiDistrict', value: '台北市信義區' },
    { label: 'areaList.ShiLinDistrict', value: '台北市士林區' },
    { label: 'areaList.BeiTouDistrict', value: '台北市北投區' },
    { label: 'areaList.NeiHuDistrict', value: '台北市內湖區' },
    { label: 'areaList.NanGangDistrict', value: '台北市南港區' },
    { label: 'areaList.WenShanDistrict', value: '台北市文山區' },
  ],
  新北市: [
    { label: 'areaList.all', value: '新北市' },
    { label: 'areaList.WanliDistrict', value: '新北市萬里區' },
    { label: 'areaList.JinshanDistrict', value: '新北市金山區' },
    { label: 'areaList.BanqiaoDistrict', value: '新北市板橋區' },
    { label: 'areaList.XizhiDistrict', value: '新北市汐止區' },
    { label: 'areaList.ShenkengDistrict', value: '新北市深坑區' },
    { label: 'areaList.ShidingDistrict', value: '新北市石碇區' },
    { label: 'areaList.RuifangDistrict', value: '新北市瑞芳區' },
    { label: 'areaList.PingxiDistrict', value: '新北市平溪區' },
    { label: 'areaList.ShuangxiDistrict', value: '新北市雙溪區' },
    { label: 'areaList.GongliaoDistrict', value: '新北市貢寮區' },
    { label: 'areaList.XindianDistrict', value: '新北市新店區' },
    { label: 'areaList.PinglinDistrict', value: '新北市坪林區' },
    { label: 'areaList.WulaiDistrict', value: '新北市烏來區' },
    { label: 'areaList.YongheDistrict', value: '新北市永和區' },
    { label: 'areaList.ZhongheDistrict', value: '新北市中和區' },
    { label: 'areaList.TuchengDistrict', value: '新北市土城區' },
    { label: 'areaList.SanxiaDistrict', value: '新北市三峽區' },
    { label: 'areaList.ShulinDistrict', value: '新北市樹林區' },
    { label: 'areaList.YinggeDistrict', value: '新北市鶯歌區' },
    { label: 'areaList.SanchongDistrict', value: '新北市三重區' },
    { label: 'areaList.XinzhuangDistrict', value: '新北市新莊區' },
    { label: 'areaList.TaishanDistrict', value: '新北市泰山區' },
    { label: 'areaList.LinkouDistrict', value: '新北市林口區' },
    { label: 'areaList.LuzhouDistrict', value: '新北市蘆洲區' },
    { label: 'areaList.WuguDistrict', value: '新北市五股區' },
    { label: 'areaList.BaliDistrict', value: '新北市八里區' },
    { label: 'areaList.TamsuiDistrict', value: '新北市淡水區' },
    { label: 'areaList.SanzhiDistrict', value: '新北市三芝區' },
    { label: 'areaList.ShimenDistrict', value: '新北市石門區' },
  ],
  桃園市: [
    { label: 'areaList.all', value: '桃園市' },
    { label: 'areaList.ZhongliDistrict', value: '桃園市中壢區' },
    { label: 'areaList.PingzhenDistrict', value: '桃園市平鎮區' },
    { label: 'areaList.LongtanDistrict', value: '桃園市龍潭區' },
    { label: 'areaList.YangmeiDistrict', value: '桃園市楊梅區' },
    { label: 'areaList.XinwuDistrict', value: '桃園市新屋區' },
    { label: 'areaList.GuanyinDistrict', value: '桃園市觀音區' },
    { label: 'areaList.TaoyuanDistrict', value: '桃園市桃園區' },
    { label: 'areaList.GuishanDistrict', value: '桃園市龜山區' },
    { label: 'areaList.BadeDistrict', value: '桃園市八德區' },
    { label: 'areaList.DaxiDistrict', value: '桃園市大溪區' },
    { label: 'areaList.FuxingDistrict', value: '桃園市復興區' },
    { label: 'areaList.DayuanDistrict', value: '桃園市大園區' },
    { label: 'areaList.LuzhuDistrict', value: '桃園市蘆竹區' },
  ],
  台中市: [
    { label: 'areaList.all', value: '台中市' },
    { label: 'areaList.CentralDistrict', value: '台中市中區' },
    { label: 'areaList.EastDistrict', value: '台中市東區' },
    { label: 'areaList.SouthDistrict', value: '台中市南區' },
    { label: 'areaList.WestDistrict', value: '台中市西區' },
    { label: 'areaList.NorthDistrict', value: '台中市北區' },
    { label: 'areaList.BeiTunDistrict', value: '台中市北屯區' },
    { label: 'areaList.XiTunDistrict', value: '台中市西屯區' },
    { label: 'areaList.NanTunDistrict', value: '台中市南屯區' },
    { label: 'areaList.TaipingDistrict', value: '台中市太平區' },
    { label: 'areaList.DaLiDistrict', value: '台中市大里區' },
    { label: 'areaList.WuFengDistrict', value: '台中市霧峰區' },
    { label: 'areaList.WuRiDistrict', value: '台中市烏日區' },
    { label: 'areaList.FengYuanDistrict', value: '台中市豐原區' },
    { label: 'areaList.HouLiDistrict', value: '台中市后里區' },
    { label: 'areaList.ShiGangDistrict', value: '台中市石岡區' },
    { label: 'areaList.DongShiDistrict', value: '台中市東勢區' },
    { label: 'areaList.HePingDistrict', value: '台中市和平區' },
    { label: 'areaList.XinSheDistrict', value: '台中市新社區' },
    { label: 'areaList.TanZiDistrict', value: '台中市潭子區' },
    { label: 'areaList.DaYaDistrict', value: '台中市大雅區' },
    { label: 'areaList.ShenGangDistrict', value: '台中市神岡區' },
    { label: 'areaList.DaDuDistrict', value: '台中市大肚區' },
    { label: 'areaList.ShaLuDistrict', value: '台中市沙鹿區' },
    { label: 'areaList.LongJingDistrict', value: '台中市龍井區' },
    { label: 'areaList.WuQiDistrict', value: '台中市梧棲區' },
    { label: 'areaList.QingShuiDistrict', value: '台中市清水區' },
    { label: 'areaList.DaJiaDistrict', value: '台中市大甲區' },
    { label: 'areaList.WaiPuDistrict', value: '台中市外埔區' },
    { label: 'areaList.DaAnDistrict', value: '台中市大安區' },
  ],
  台南市: [
    { label: 'areaList.all', value: '台南市' },
    { label: 'areaList.ZhongXiDistrict', value: '台南市中西區' },
    { label: 'areaList.EastDistrict', value: '台南市東區' },
    { label: 'areaList.SouthDistrict', value: '台南市南區' },
    { label: 'areaList.NorthDistrict', value: '台南市北區' },
    { label: 'areaList.AnPingDistrict', value: '台南市安平區' },
    { label: 'areaList.AnNanDistrict', value: '台南市安南區' },
    { label: 'areaList.YongKangDistrict', value: '台南市永康區' },
    { label: 'areaList.GuiRenDistrict', value: '台南市歸仁區' },
    { label: 'areaList.XinHuaDistrict', value: '台南市新化區' },
    { label: 'areaList.ZuoZhenDistrict', value: '台南市左鎮區' },
    { label: 'areaList.YuJingDistrict', value: '台南市玉井區' },
    { label: 'areaList.NanXiDistrict', value: '台南市楠西區' },
    { label: 'areaList.NanHuaDistrict', value: '台南市南化區' },
    { label: 'areaList.RenDeDistrict', value: '台南市仁德區' },
    { label: 'areaList.GuanMiaoDistrict', value: '台南市關廟區' },
    { label: 'areaList.LongQiDistrict', value: '台南市龍崎區' },
    { label: 'areaList.GuanTianDistrict', value: '台南市官田區' },
    { label: 'areaList.MaDouDistrict', value: '台南市麻豆區' },
    { label: 'areaList.JiaLiDistrict', value: '台南市佳里區' },
    { label: 'areaList.XiGangDistrict', value: '台南市西港區' },
    { label: 'areaList.QiGuDistrict', value: '台南市七股區' },
    { label: 'areaList.JiangJunDistrict', value: '台南市將軍區' },
    { label: 'areaList.XueJiaDistrict', value: '台南市學甲區' },
    { label: 'areaList.BeiMenDistrict', value: '台南市北門區' },
    { label: 'areaList.XinYingDistrict', value: '台南市新營區' },
    { label: 'areaList.HouBiDistrict', value: '台南市後壁區' },
    { label: 'areaList.BaiHeDistrict', value: '台南市白河區' },
    { label: 'areaList.DongShanDistrict', value: '台南市東山區' },
    { label: 'areaList.LiuJiaDistrict', value: '台南市六甲區' },
    { label: 'areaList.XiaYingDistrict', value: '台南市下營區' },
    { label: 'areaList.LiuYingDistrict', value: '台南市柳營區' },
    { label: 'areaList.YanShuiDistrict', value: '台南市鹽水區' },
    { label: 'areaList.ShanHuaDistrict', value: '台南市善化區' },
    { label: 'areaList.DaNeiDistrict', value: '台南市大內區' },
    { label: 'areaList.ShanShangDistrict', value: '台南市山上區' },
    { label: 'areaList.XinShiDistrict', value: '台南市新市區' },
    { label: 'areaList.AnDingDistrict', value: '台南市安定區' },
  ],
  高雄市: [
    { label: 'areaList.all', value: '高雄市' },
    { label: 'areaList.XinXingDistrict', value: '高雄市新興區' },
    { label: 'areaList.QianJinDistrict', value: '高雄市前金區' },
    { label: 'areaList.LingYaDistrict', value: '高雄市苓雅區' },
    { label: 'areaList.YanChengDistrict', value: '高雄市鹽埕區' },
    { label: 'areaList.GuShanDistrict', value: '高雄市鼓山區' },
    { label: 'areaList.QiJinDistrict', value: '高雄市旗津區' },
    { label: 'areaList.QianZhenDistrict', value: '高雄市前鎮區' },
    { label: 'areaList.SanMinDistrict', value: '高雄市三民區' },
    { label: 'areaList.NanZiDistrict', value: '高雄市楠梓區' },
    { label: 'areaList.XiaoGangDistrict', value: '高雄市小港區' },
    { label: 'areaList.ZuoYingDistrict', value: '高雄市左營區' },
    { label: 'areaList.RenWuDistrict', value: '高雄市仁武區' },
    { label: 'areaList.DaSheDistrict', value: '高雄市大社區' },
    { label: 'areaList.DongShaIslands', value: '高雄市東沙群島' },
    { label: 'areaList.NanShaIslands', value: '高雄市南沙群島' },
    { label: 'areaList.GangShanDistrict', value: '高雄市岡山區' },
    { label: 'areaList.LuZhuDistrict', value: '高雄市路竹區' },
    { label: 'areaList.ALianDistrict', value: '高雄市阿蓮區' },
    { label: 'areaList.TianLiaoDistrict', value: '高雄市田寮區' },
    { label: 'areaList.YanChaoDistrict', value: '高雄市燕巢區' },
    { label: 'areaList.QiaoTouDistrict', value: '高雄市橋頭區' },
    { label: 'areaList.ZiGuanDistrict', value: '高雄市梓官區' },
    { label: 'areaList.MiTuoDistrict', value: '高雄市彌陀區' },
    { label: 'areaList.YongAnDistrict', value: '高雄市永安區' },
    { label: 'areaList.HuNeiDistrict', value: '高雄市湖內區' },
    { label: 'areaList.FengShanDistrict', value: '高雄市鳳山區' },
    { label: 'areaList.DaLiaoDistrict', value: '高雄市大寮區' },
    { label: 'areaList.LinYuanDistrict', value: '高雄市林園區' },
    { label: 'areaList.NiaoSongDistrict', value: '高雄市鳥松區' },
    { label: 'areaList.DaShuDistrict', value: '高雄市大樹區' },
    { label: 'areaList.QiShanDistrict', value: '高雄市旗山區' },
    { label: 'areaList.MeiNongDistrict', value: '高雄市美濃區' },
    { label: 'areaList.LiuguiDistrict', value: '高雄市六龜區' },
    { label: 'areaList.NeiMenDistrict', value: '高雄市內門區' },
    { label: 'areaList.ShanLinDistrict', value: '高雄市杉林區' },
    { label: 'areaList.JiaXianDistrict', value: '高雄市甲仙區' },
    { label: 'areaList.TaoYuanDistrict', value: '高雄市桃源區' },
    { label: 'areaList.NaMaXiaDistrict', value: '高雄市那瑪夏區' },
    { label: 'areaList.MaoLinDistrict', value: '高雄市茂林區' },
    { label: 'areaList.QieDingDistrict', value: '高雄市茄萣區' },
  ],
  基隆市: [
    { label: 'areaList.all', value: '基隆市' },
    { label: 'areaList.RenAiDistrict', value: '基隆市仁愛區' },
    { label: 'areaList.XinYiDistrict', value: '基隆市信義區' },
    { label: 'areaList.ZhongZhengDistrict', value: '基隆市中正區' },
    { label: 'areaList.ZhongShanDistrict', value: '基隆市中山區' },
    { label: 'areaList.AnLeDistrict', value: '基隆市安樂區' },
    { label: 'areaList.NuanNuanDistrict', value: '基隆市暖暖區' },
    { label: 'areaList.QiDuDistrict', value: '基隆市七堵區' },
  ],
  宜蘭縣: [
    { label: 'areaList.all', value: '宜蘭縣' },
    { label: 'areaList.YilanCity', value: '宜蘭縣宜蘭縣宜蘭市' },
    { label: 'areaList.TouchengTown', value: '宜蘭縣頭城鎮' },
    { label: 'areaList.JiaoxiTownship', value: '宜蘭縣礁溪鄉' },
    { label: 'areaList.ZhuangweiTownship', value: '宜蘭縣壯圍鄉' },
    { label: 'areaList.YuanshanTownship', value: '宜蘭縣員山鄉' },
    { label: 'areaList.LuodongTown', value: '宜蘭縣羅東鎮' },
    { label: 'areaList.SansingTownship', value: '宜蘭縣三星鄉' },
    { label: 'areaList.DatongTownship', value: '宜蘭縣大同鄉' },
    { label: 'areaList.WujieTownship', value: '宜蘭縣五結鄉' },
    { label: 'areaList.DongshanTownship', value: '宜蘭縣冬山鄉' },
    { label: 'areaList.SuaoTown', value: '宜蘭縣蘇澳鎮' },
    { label: 'areaList.NanaoTownship', value: '宜蘭縣南澳鄉' },
    { label: 'areaList.DiaoyuIslands', value: '宜蘭縣釣魚台列嶼' },
  ],
  新竹市: [
    { label: 'areaList.all', value: '新竹市' },
    { label: 'areaList.EastDistrict', value: '新竹市東區' },
    { label: 'areaList.NorthDistrict', value: '新竹市北區' },
    { label: 'areaList.XiangshanDistrict', value: '新竹市香山區' },
  ],
  新竹縣: [
    { label: 'areaList.all', value: '新竹縣' },
    { label: 'areaList.ZhubeiCity', value: '新竹縣竹北市' },
    { label: 'areaList.HukouTown', value: '新竹縣湖口鄉' },
    { label: 'areaList.XinfengTown', value: '新竹縣新豐鄉' },
    { label: 'areaList.XinpuTown', value: '新竹縣新埔鎮' },
    { label: 'areaList.GuanxiTown', value: '新竹縣關西鎮' },
    { label: 'areaList.QionglinTown', value: '新竹縣芎林鄉' },
    { label: 'areaList.BaoshanTown', value: '新竹縣寶山鄉' },
    { label: 'areaList.ZhudongTown', value: '新竹縣竹東鎮' },
    { label: 'areaList.WufengTown', value: '新竹縣五峰鄉' },
    { label: 'areaList.HengshanTown', value: '新竹縣橫山鄉' },
    { label: 'areaList.JianshihTown', value: '新竹縣尖石鄉' },
    { label: 'areaList.BeipuTown', value: '新竹縣北埔鄉' },
    { label: 'areaList.EmeiTown', value: '新竹縣峨眉鄉' },
  ],
  苗栗縣: [
    { label: 'areaList.all', value: '苗栗縣' },
    { label: 'areaList.ZhunanTown', value: '苗栗縣竹南鎮' },
    { label: 'areaList.ToufenCity', value: '苗栗縣頭份市' },
    { label: 'areaList.SanwanTown', value: '苗栗縣三灣鄉' },
    { label: 'areaList.NanzhuangTown', value: '苗栗縣南庄鄉' },
    { label: 'areaList.ShitanTown', value: '苗栗縣獅潭鄉' },
    { label: 'areaList.HoulongTown', value: '苗栗縣後龍鎮' },
    { label: 'areaList.TongxiaoTown', value: '苗栗縣通霄鎮' },
    { label: 'areaList.YuanliTown', value: '苗栗縣苑裡鎮' },
    { label: 'areaList.MiaoliCity', value: '苗栗縣苗栗市' },
    { label: 'areaList.ZaoqiaoTown', value: '苗栗縣造橋鄉' },
    { label: 'areaList.TouwuTown', value: '苗栗縣頭屋鄉' },
    { label: 'areaList.GongguanTown', value: '苗栗縣公館鄉' },
    { label: 'areaList.DahuTown', value: '苗栗縣大湖鄉' },
    { label: 'areaList.TaianTown', value: '苗栗縣泰安鄉' },
    { label: 'areaList.TongluoTown', value: '苗栗縣銅鑼鄉' },
    { label: 'areaList.SanyiTown', value: '苗栗縣三義鄉' },
    { label: 'areaList.MiaoliCountyXihuTown', value: '苗栗縣西湖鄉' },
    { label: 'areaList.ZhuolanTown', value: '苗栗縣卓蘭鎮' },
  ],
  彰化縣: [
    { label: 'areaList.all', value: '彰化縣' },
    { label: 'areaList.ChanghuaCity', value: '彰化縣彰化市' },
    { label: 'areaList.FenyuanTown', value: '彰化縣芬園鄉' },
    { label: 'areaList.HuatangTown', value: '彰化縣花壇鄉' },
    { label: 'areaList.XiushuiTown', value: '彰化縣秀水鄉' },
    { label: 'areaList.LugangTown', value: '彰化縣鹿港鎮' },
    { label: 'areaList.FuxingTown', value: '彰化縣福興鄉' },
    { label: 'areaList.XianshiTown', value: '彰化縣線西鄉' },
    { label: 'areaList.HemeiTown', value: '彰化縣和美鎮' },
    { label: 'areaList.ShengangTown', value: '彰化縣伸港鄉' },
    { label: 'areaList.YuanlinCity', value: '彰化縣員林市' },
    { label: 'areaList.ShetouTown', value: '彰化縣社頭鄉' },
    { label: 'areaList.YongjingTown', value: '彰化縣永靖鄉' },
    { label: 'areaList.PuxinTown', value: '彰化縣埔心鄉' },
    { label: 'areaList.ChanghuaCountyXihuTown', value: '彰化縣溪湖鎮' },
    { label: 'areaList.DacunTown', value: '彰化縣大村鄉' },
    { label: 'areaList.PuyanTown', value: '彰化縣埔鹽鄉' },
    { label: 'areaList.TianzhongTown', value: '彰化縣田中鎮' },
    { label: 'areaList.BeidouTown', value: '彰化縣北斗鎮' },
    { label: 'areaList.TianweiTown', value: '彰化縣田尾鄉' },
    { label: 'areaList.PitouTown', value: '彰化縣埤頭鄉' },
    { label: 'areaList.XizhouTown', value: '彰化縣溪州鄉' },
    { label: 'areaList.ZhutangTown', value: '彰化縣竹塘鄉' },
    { label: 'areaList.ErlinTown', value: '彰化縣二林鎮' },
    { label: 'areaList.DachengTown', value: '彰化縣大城鄉' },
    { label: 'areaList.FangyuanTown', value: '彰化縣芳苑鄉' },
    { label: 'areaList.ErshuiTown', value: '彰化縣二水鄉' },
  ],
  南投縣: [
    { label: 'areaList.all', value: '南投縣' },
    { label: 'areaList.NantouCity', value: '南投縣南投市' },
    { label: 'areaList.ZhongliaoTown', value: '南投縣中寮鄉' },
    { label: 'areaList.CaotunTown', value: '南投縣草屯鎮' },
    { label: 'areaList.GuoxingTown', value: '南投縣國姓鄉' },
    { label: 'areaList.PuliTown', value: '南投縣埔里鎮' },
    { label: 'areaList.RenaiTown', value: '南投縣仁愛鄉' },
    { label: 'areaList.MingjianTown', value: '南投縣名間鄉' },
    { label: 'areaList.JijiTown', value: '南投縣集集鎮' },
    { label: 'areaList.ShuiliTown', value: '南投縣水里鄉' },
    { label: 'areaList.YuchiTown', value: '南投縣魚池鄉' },
    { label: 'areaList.XinyiTown', value: '南投縣信義鄉' },
    { label: 'areaList.ZhushanTown', value: '南投縣竹山鎮' },
    { label: 'areaList.LuguTown', value: '南投縣鹿谷鄉' },
  ],
  嘉義市: [
    { label: 'areaList.all', value: '嘉義市' },
    { label: 'areaList.EastDistrict', value: '嘉義市東區' },
    { label: 'areaList.WestDistrict', value: '嘉義市西區' },
  ],
  嘉義縣: [
    { label: 'areaList.all', value: '嘉義縣' },
    { label: 'areaList.FanluTownship', value: '嘉義縣番路鄉' },
    { label: 'areaList.MeishanTownship', value: '嘉義縣梅山鄉' },
    { label: 'areaList.ZhuqiTownship', value: '嘉義縣竹崎鄉' },
    { label: 'areaList.Alishan', value: '嘉義縣阿里山' },
    { label: 'areaList.ZhongpuTownship', value: '嘉義縣中埔鄉' },
    { label: 'areaList.DapuTownship', value: '嘉義縣大埔鄉' },
    { label: 'areaList.ShuishangTownship', value: '嘉義縣水上鄉' },
    { label: 'areaList.LucaoTownship', value: '嘉義縣鹿草鄉' },
    { label: 'areaList.TaibaoCity', value: '嘉義縣太保市' },
    { label: 'areaList.PuziCity', value: '嘉義縣朴子市' },
    { label: 'areaList.DongshiTownship', value: '嘉義縣東石鄉' },
    { label: 'areaList.LiujiaoTownship', value: '嘉義縣六腳鄉' },
    { label: 'areaList.XingangTownship', value: '嘉義縣新港鄉' },
    { label: 'areaList.MinxiongTownship', value: '嘉義縣民雄鄉' },
    { label: 'areaList.DalinTownship', value: '嘉義縣大林鎮' },
    { label: 'areaList.XikouTownship', value: '嘉義縣溪口鄉' },
    { label: 'areaList.YizhuTownship', value: '嘉義縣義竹鄉' },
    { label: 'areaList.BudaiTownship', value: '嘉義縣布袋鎮' },
  ],
  雲林縣: [
    { label: 'areaList.all', value: '雲林縣' },
    { label: 'areaList.DounanTownship', value: '雲林縣斗南鎮' },
    { label: 'areaList.DapiTownship', value: '雲林縣大埤鄉' },
    { label: 'areaList.HuweiTownship', value: '雲林縣虎尾鎮' },
    { label: 'areaList.TukuTownship', value: '雲林縣土庫鎮' },
    { label: 'areaList.BaoshungTownship', value: '雲林縣褒忠鄉' },
    { label: 'areaList.DongshiTownship_Yunlin', value: '雲林縣東勢鄉' },
    { label: 'areaList.TaixiTownship', value: '雲林縣台西鄉' },
    { label: 'areaList.LunbeiTownship', value: '雲林縣崙背鄉' },
    { label: 'areaList.MailiaoTownship', value: '雲林縣麥寮鄉' },
    { label: 'areaList.DouliuCity', value: '雲林縣斗六市' },
    { label: 'areaList.LinneiTownship', value: '雲林縣林內鄉' },
    { label: 'areaList.GukengTownship', value: '雲林縣古坑鄉' },
    { label: 'areaList.CitongTownship', value: '雲林縣莿桐鄉' },
    { label: 'areaList.XiluoTownship', value: '雲林縣西螺鎮' },
    { label: 'areaList.ErlunTownship', value: '雲林縣二崙鄉' },
    { label: 'areaList.BeigangTownship', value: '雲林縣北港鎮' },
    { label: 'areaList.ShuilinTownship', value: '雲林縣水林鄉' },
    { label: 'areaList.KouhuTownship', value: '雲林縣口湖鄉' },
    { label: 'areaList.SihuTownship', value: '雲林縣四湖鄉' },
    { label: 'areaList.YuanzhangTownship', value: '雲林縣元長鄉' },
  ],
  澎湖縣: [
    { label: 'areaList.all', value: '澎湖縣' },
    { label: 'areaList.MagongCity', value: '澎湖縣馬公市' },
    { label: 'areaList.XiyuTownship', value: '澎湖縣西嶼鄉' },
    { label: 'areaList.WangAnTownship', value: '澎湖縣望安鄉' },
    { label: 'areaList.QimeiTownship', value: '澎湖縣七美鄉' },
    { label: 'areaList.BaishaTownship', value: '澎湖縣白沙鄉' },
    { label: 'areaList.HuxiTownship', value: '澎湖縣湖西鄉' },
  ],
  金門縣: [
    { label: 'areaList.all', value: '金門縣' },
    { label: 'areaList.JinshaTownship', value: '金門縣金沙鎮' },
    { label: 'areaList.JinhuTownship', value: '金門縣金湖鎮' },
    { label: 'areaList.JinningTownship', value: '金門縣金寧鄉' },
    { label: 'areaList.JinchengCity', value: '金門縣金城鎮' },
    { label: 'areaList.LieyuTownship', value: '金門縣烈嶼鄉' },
    { label: 'areaList.WuqiuTownship', value: '金門縣烏坵鄉' },
  ],
  屏東縣: [
    { label: 'areaList.all', value: '屏東縣' },
    { label: 'areaList.PingtungCity', value: '屏東縣屏東市' },
    { label: 'areaList.SandiMenTownship', value: '屏東縣三地門鄉' },
    { label: 'areaList.WutaiTownship', value: '屏東縣霧台鄉' },
    { label: 'areaList.MajiaTownship', value: '屏東縣瑪家鄉' },
    { label: 'areaList.JiuruTownship', value: '屏東縣九如鄉' },
    { label: 'areaList.LigangTownship', value: '屏東縣里港鄉' },
    { label: 'areaList.GaoshuTownship', value: '屏東縣高樹鄉' },
    { label: 'areaList.YanpuTownship', value: '屏東縣鹽埔鄉' },
    { label: 'areaList.ChangzhiTownship', value: '屏東縣長治鄉' },
    { label: 'areaList.LinluoTownship', value: '屏東縣麟洛鄉' },
    { label: 'areaList.ZhutianTownship', value: '屏東縣竹田鄉' },
    { label: 'areaList.NeipuTownship', value: '屏東縣內埔鄉' },
    { label: 'areaList.WandanTownship', value: '屏東縣萬丹鄉' },
    { label: 'areaList.ChaozhouTownship', value: '屏東縣潮州鎮' },
    { label: 'areaList.TaiwuTownship', value: '屏東縣泰武鄉' },
    { label: 'areaList.LaiyiTownship', value: '屏東縣來義鄉' },
    { label: 'areaList.WanluanTownship', value: '屏東縣萬巒鄉' },
    { label: 'areaList.KandingTownship', value: '屏東縣崁頂鄉' },
    { label: 'areaList.XinpiTownship', value: '屏東縣新埤鄉' },
    { label: 'areaList.NanzhouTownship', value: '屏東縣南州鄉' },
    { label: 'areaList.LinbianTownship', value: '屏東縣林邊鄉' },
    { label: 'areaList.DonggangTownship', value: '屏東縣東港鎮' },
    { label: 'areaList.LiuqiuTownship', value: '屏東縣琉球鄉' },
    { label: 'areaList.JiadongTownship', value: '屏東縣佳冬鄉' },
    { label: 'areaList.XinyuanTownship', value: '屏東縣新園鄉' },
    { label: 'areaList.FangliaoTownship', value: '屏東縣枋寮鄉' },
    { label: 'areaList.FangshanTownship', value: '屏東縣枋山鄉' },
    { label: 'areaList.ChunriTownship', value: '屏東縣春日鄉' },
    { label: 'areaList.ShiziTownship', value: '屏東縣獅子鄉' },
    { label: 'areaList.ChechengTownship', value: '屏東縣車城鄉' },
    { label: 'areaList.MudanTownship', value: '屏東縣牡丹鄉' },
    { label: 'areaList.HengchunTownship', value: '屏東縣恆春鎮' },
    { label: 'areaList.ManjhouTownship', value: '屏東縣滿州鄉' },
  ],
  台東縣: [
    { label: 'areaList.all', value: '台東縣' },
    { label: 'areaList.TaitungCity', value: '台東縣台東市' },
    { label: 'areaList.LudaoTownship', value: '台東縣綠島鄉' },
    { label: 'areaList.LanyuTownship', value: '台東縣蘭嶼鄉' },
    { label: 'areaList.YanpingTownship', value: '台東縣延平鄉' },
    { label: 'areaList.BeinanTownship', value: '台東縣卑南鄉' },
    { label: 'areaList.LuyeTownship', value: '台東縣鹿野鄉' },
    { label: 'areaList.GuanshanTownship', value: '台東縣關山鎮' },
    { label: 'areaList.HaiduanTownship', value: '台東縣海端鄉' },
    { label: 'areaList.ChihshangTownship', value: '台東縣池上鄉' },
    { label: 'areaList.DongheTownship', value: '台東縣東河鄉' },
    { label: 'areaList.ChenggongTownship', value: '台東縣成功鎮' },
    { label: 'areaList.ChangbinTownship', value: '台東縣長濱鄉' },
    { label: 'areaList.TaimaliTownship', value: '台東縣太麻里' },
    { label: 'areaList.JinfengTownship', value: '台東縣金峰鄉' },
    { label: 'areaList.DawuTownship', value: '台東縣大武鄉' },
    { label: 'areaList.DarenTownship', value: '台東縣達仁鄉' },
  ],
  花蓮縣: [
    { label: 'areaList.all', value: '花蓮縣' },
    { label: 'areaList.HualienCity', value: '花蓮縣花蓮市' },
    { label: 'areaList.SinChengTownship', value: '花蓮縣新城鄉' },
    { label: 'areaList.XiuLinTownship', value: '花蓮縣秀林鄉' },
    { label: 'areaList.JiAnTownship', value: '花蓮縣吉安鄉' },
    { label: 'areaList.ShoufengTownship', value: '花蓮縣壽豐鄉' },
    { label: 'areaList.FengLinTownship', value: '花蓮縣鳳林鎮' },
    { label: 'areaList.GuangFuTownship', value: '花蓮縣光復鄉' },
    { label: 'areaList.FengBinTownship', value: '花蓮縣豐濱鄉' },
    { label: 'areaList.RuisuiTownship', value: '花蓮縣瑞穗鄉' },
    { label: 'areaList.WanrongTownship', value: '花蓮縣萬榮鄉' },
    { label: 'areaList.YuliTownship', value: '花蓮縣玉里鎮' },
    { label: 'areaList.ZhuoxiTownship', value: '花蓮縣卓溪鄉' },
    { label: 'areaList.FuliTownship', value: '花蓮縣富里鄉' },
  ],
  連江縣: [
    { label: 'areaList.all', value: '連江縣' },
    { label: 'areaList.NanganTownship', value: '連江縣南竿鄉' },
    { label: 'areaList.BeiganTownship', value: '連江縣北竿鄉' },
    { label: 'areaList.JuGuangTownship', value: '連江縣莒光鄉' },
    { label: 'areaList.DongyinTownship', value: '連江縣東引鄉' },
  ],
};
